import React from 'react';
import { Accordion, List } from 'semantic-ui-react';

export default [
  {
    title: 'How do I download the video with my logo?',
    content: (
      <Accordion.Content>
        After you create your logo you can click on the
        {' '}
        <b>Download</b>
        {' '}
        button at the bottom of the page and follow the steps to order your Download.
        The video will be in MP4 file format.
      </Accordion.Content>
    ),
  },
  {
    title: 'Will my video have watermark?',
    content: `No. Only the logo image have the watermark shown in the website.
     The generated video will not have the watermark it will look like the sample at the download popup.`,
  },
  {
    title: 'The website is not working as expected. My logo is not working. What should I do?',
    content: (
      <Accordion.Content>
        <p>
          Please try to access our website using a different browser or in a different device.
        </p>
        <p>
          If the website still does not work, please contact us via email:
          {` `}
          <a href="mailto:support@kassellabs.io">support@kassellabs.io</a>
          {` `}
          and let us know which device and browser you are using.
        </p>

      </Accordion.Content>
    ),
  },
  {
    title: 'After the payment how long will it take for me to receive the video?',
    content: 'You should receive it in 1-2 days. You will receive a payment confirmation email with the estimated time to receive your video.',
  },
  {
    title: 'How can I pay?',
    content: `
      We accept payments via the website, the options are credit cards, some debit cards too, and PayPal.
      The payment form is available at the download page after you click on the 'Download MP4 Video' button.
      If the payment doesn't work for you, you can also try to make a transference direct to our PayPal account via payments@kassellabs.io.
      If you make it and don't receive a confirmation mail, please check if the payment went successfully or contact us.
    `,
  },
  {
    title: 'I paid but didn\'t receive the video, what should I do?',
    content: (
      <Accordion.Content>

      <List bulleted>
        <List.Item>Check if your payment was successfully made and you receive our confirmation email.</List.Item>
        <List.Item>Check on the download link available at our confirmation email and your payment
          receipt, if your video is ready for download.</List.Item>
        <List.Item>
          Check in your email spam box if you did not receive it.
        </List.Item>
      </List>

        <p>
          Remember that only payment made via the website are processed
          automatically and you should receive a confirmation email from us.
          If did not receive it, follow the steps below.
        </p>
        <List bulleted>
          <List.Item>
            If you paid via PayPal:
            <List bulleted>
              <List.Item>
                Check in your account if your payment went successfully.
                You can try to pay again if it was not made.
              </List.Item>
              <List.Item>
                Check if the email you are expecting is the same as your PayPal email.
              </List.Item>
              <List.Item>
                Check if your payment was processed on time.
                Only payments made via Credit Card or PayPal funds are completed on time.
                If you use another payment method, please check if it still pending.
              </List.Item>
            </List>
          </List.Item>
          <List.Item>
            If you paid via Credit Card:
            <List bulleted>
              <List.Item>
                Check with your credit card issuer if the payment was made successfully.
              </List.Item>
              <List.Item>
                Check if you have received an receipt via email.
              </List.Item>
            </List>
          </List.Item>
        </List>
        <p>
          If you still did not receive your video, please contact us via email:
          {` `}
          <a href="mailto:support@kassellabs.io">support@kassellabs.io</a>
        </p>
      </Accordion.Content>
    ),
  },
  // {
  //   title: 'How can I receive the video in another email?',
  //   content: `Follow the steps to Download again and type another email in the email box.
  //   You can add more emails if you want, the video will be sent to all of them.
  //   You do not need to pay again.`,
  // },
  {
    title: 'I am having issues to pay via credit card, is there another method?',
    content: (
      <Accordion.Content>
        <p>
          You can also pay via PayPal creating an account or
          using an existing one and paying via the website payment.
        </p>
        <p>
          {`
          You can also try to make a transference direct to our PayPal account via payments@kassellabs.io.
          If you make it and don't receive a confirmation mail, please check if the payment went successfully or contact us.
        `}
        </p>
        <p>
          If you still have trouble to pay, please contact us via email:
          {' '}
          <a href="mailto:support@kassellabs.io">support@kassellabs.io</a>
          .
          Do not forget to include details about the problem you are having.
        </p>
      </Accordion.Content>
    ),
  },
  {
    title: 'I want to pay in another currency, how much it is?',
    content: `It will be of the same value. Use the market conversion to send the respective amount into US Dollar. On PayPal, you can convert the payment value to US Dollar too.
    If you pay via credit card which is not in USD currency, the amount will be converted.`,
  },
  {
    title: 'How do I download on my iPhone/iPad?',
    content: (
      <Accordion.Content>
        <p>
          Downloading files on the iOS devices might not be so easy.
          When you open the link on Safari it will just play your
          video and does not allow to save as MP4 file.
        </p>
        <p>
          We have a video to help you download your video by using an app, watch
          {' '}
          <a href="https://www.youtube.com/watch?v=XZshsVMjsp8">here on YouTube</a>
          .
        </p>
        <p>
          Another alternative is to use another device.
          You should have no problem with a desktop.
        </p>
      </Accordion.Content>
    ),
  },
  {
    title: 'Can I post my intro in a social media?',
    content: `We recommend to share the link to your intro.
    Posting the video may subject to copyright and might be muted or blocked by the platform.
    We are not responsible for the reproduction of videos on third-party services.
    We can change the music of your video if you want to prevent copyright problems, please contact us via email for details if you are interested.
    `,
  },
  {
    title: 'I\'m concerned about copyright, can I use my video for commercial purpose?',
    content: (
      <Accordion.Content>
        <p>
          For copyright issues, this also depends on your usage, the content and
          where the video will be published.
        </p>
        <p>We advise caution on such purpose.</p>
        <p>The video produced may have copyrighted contents, such as music and background video.</p>
      </Accordion.Content>
    ),
  },
  {
    title: 'I made a typo or I want to make a change in the text of my rendered video. Can I?',
    content: (
      <Accordion.Content>
        <p>
          As is stated in our Terms of Service below:
          We are not responsible for any errors (typos, misspellings)
          made when creating your content, the rendered video content is
          reproduced exactly how it was received.
        </p>
        <p>
          We recommend you to edit your intro and request to download again to fix it.
          Any fixes requested to us will be subjected to our current availability and may have extra costs.
        </p>
      </Accordion.Content>
    ),
  },
  {
    title: 'My download is not working as expected, what can I do?',
    content: (
      <Accordion.Content>
        <p>
          Please try to open it in another video player or in another device.
        </p>
        <p>
          If the video still does not work, please contact us informing
          which device and video player you are using.
        </p>
      </Accordion.Content>
    ),
  },
  {
    title: 'I can\'t download my video, my link is not working, what can I do?',
    content: (
      <Accordion.Content>
        <p>
          If you're having trouble to download it, please make sure
          you are not using a VPN or proxy that may block some websites.
          Try to open it in another browser or another device.
        </p>
        <p>
          If the problem still persists, please try again later or contact us via email.
        </p>
      </Accordion.Content>
    ),
  },
  {
    title: 'Can I use it with a PowerPoint presentation?',
    content: (
      <Accordion.Content>
        <p>
          Yes! When you download the video it will be an MP4
          file and you can import it in your presentation.
        </p>
      </Accordion.Content>
    ),
  },
  {
    title: 'Do you store my Credit Card details? What data is collected?',
    content: (
      <Accordion.Content>
        <p>
          No! We do not store your credit card data.
          The details of your credit card are only exchanged
          with the payment gateways like PayPal and Stripe.
        </p>
        <p>
          We only store the data of your animation created
          and your email when you request to download.
        </p>
      </Accordion.Content>
    ),
  },
  {
    title: 'Can I use a different image in the video?',
    content: `Yes, we also work with advanced customizations.
              We can include a new image, or replace an image in the video.
              Please contact us via email to request more details about it.`,
  },
  {
    title: 'My video file it\'s not playing, is it corrupted?',
    content: (
      <Accordion.Content>
        <p>
          Please download the file again and try with different devices
          and video players. We recommend VLC Video Player.
        </p>
        <p>
          {`
          Try also other video files in .mp4 format to make sure if it's not
          missing codecs on your device and the problem is not with the file you
          received.
          `}
        </p>
        <p>
          If you still have trouble to play your video, please contact us via email:
          {' '}
          <a href="mailto:support@kassellabs.io">support@kassellabs.io</a>
          .
          Do not forget to include details about the problem you are having.
        </p>
      </Accordion.Content>
    ),
  },
  // {
  //   title: 'I\'m seeing a glitch/bug on my creation, will that be in my video too?',
  //   content: `No, the rendered video will be perfect. If not, we will make our best to make it sure.
  //     We have known glitches that happen in different browsers.
  //     Each browser may have some particularities so it's hard to make the animation look the same in all of them.`,
  // },
  {
    title: 'I want to delete my content from your database, how do I proceed?',
    content: (<Accordion.Content>
    <p>If the content is not in accordance with our terms of service, it can be deleted. Just report it to us.
      If you want to delete the content you created we may request additional information to prove that you are the author.
      Please send us an email sharing the link to the content you want to delete and the date and time you created it, including your timezone.
      You can check that information on your browser history.{` `}
      <a href="mailto:removal@kassellabs.io?subject=Content removal request&body=Hi!%0d%0a
I want to delete the following contents from your website:%0d%0a
{Links to the content}%0d%0a
%0d%0a
I created the content at:%0d%0a
{Date, Time, Timezone}%0d%0a
%0d%0a
Thanks!%0d%0a
      ">Click here to send your content removal request</a>
      </p>
      </Accordion.Content>)
    ,
  },
  {
    title: 'I paid for a video and I want to cancel it, is it possible?',
    content: `If the order was processed according to our terms of service and started to render, or it's already sent to your email,
it's not possible to cancel it. As the video was already processed and sent we can not refund the payment.
We only can make refunds if we don't sent the video in accordance to our terms. If you ask for a refund before the video started to be rendered,
it may depend on our availability to check your email with enough time to cancel your video.`
  },
];
