import React from 'react';

import ProductPageLayout from '../components/ProductPageLayout';
import faq from '../faq/the-last-of-us';
import Terms from '../termsOfService/the-last-of-us.mdx';

const TheLastOfUsPage = () => (
  <ProductPageLayout
    title="The Last of Us Intro Creator"
    emailAlias="thelastofus"
    website="https://thelastofusintrocreator.kassellabs.io"
    faq={faq}
    mergeTerms={false}
    mergeFaq={false}
    terms={<Terms />}
  />
);

export default TheLastOfUsPage;

